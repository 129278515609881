import React from "react";
import { RouteComponentProps } from "@reach/router";

import styles from "./styles.module.scss";

import { GlobalNavComponent } from "../global-nav";
import { AboutComponent } from "../about";
import { BenefitsComponent } from "../benefits";
import { RequirementsComponent } from "../criteria";
import { FaqComponent } from "../faq";
import { GlobalFooterComponent } from "../global-footer";
import { MotivationComponent } from "../motivation";
import { CreatorsComponent } from "../creators";
import { FloatNavigationComponent } from "../float-navigation";
import { useMediaQuery } from "react-responsive";
import { Media } from "../../shared/media/types";

export const MainPage = (_: RouteComponentProps) => {
  const isMobile = useMediaQuery({ maxWidth: Media.TS });

  return (
    <>
      <GlobalNavComponent />

      <main className={styles.main}>
        {!isMobile && <FloatNavigationComponent />}
        <AboutComponent />
        <MotivationComponent />
        <CreatorsComponent />
        <BenefitsComponent />
        <RequirementsComponent />
        <FaqComponent />
      </main>

      <GlobalFooterComponent />
      {isMobile && <FloatNavigationComponent />}
    </>
  );
};
