export enum Media {
  MS = "320px",
  MM = "375px",
  ML = "425px",
  TS = "672px",
  TM = "768px",
  TL = "820px",
  LS = "1024px",
  LM = "1280px",
  LL = "1440px",
}
