import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { scrollTop } from "../../shared/utils/scroll-top";
import {
  APP_ID,
  ENVIRONMENT,
  GENOME_ID,
  GLOBAL_NAVIGATION_URL,
} from "../../shared/constants";

export const GlobalNavComponent = () => {
  const { i18n } = useTranslation();
  const language = i18n.language?.toLowerCase();

  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("id", "global-navigation");
    script.setAttribute("type", "text/javascript");
    script.src = GLOBAL_NAVIGATION_URL[ENVIRONMENT];
    script.async = true;

    document.body.appendChild(script);
    scrollTop();
  }, []);

  return (
    <global-navigation
      class="global-navigation--default"
      app-id={APP_ID}
      genome-id-emea={GENOME_ID}
      genome-id-ncsa={GENOME_ID}
      locale={language}
    />
  );
};
