import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import styles from "./styles.module.scss";

import creator1 from "../../assets/images/creators/section-2-creator-l.png";
import creator2 from "../../assets/images/creators/section-2-creator-r.png";

import { ButtonLink } from "../../shared/button/button-link";
import { book } from "../../navigation/book";

export const MotivationComponent = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const ctaWrapper = document.querySelector(`.${styles.motivation__link}`);

    if (!ctaWrapper) {
      return;
    }

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        }

        document
          .querySelectorAll(`.${styles.creator}`)
          ?.forEach((creator) =>
            creator.classList.remove(styles.creatorHidden)
          );

        observer.unobserve(ctaWrapper);
      });
    });

    observer.observe(ctaWrapper);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <section className={classNames(styles.section, styles.section__motivation)}>
      <img
        src={creator1}
        alt="creator"
        className={classNames(
          styles.creator,
          styles.creator1,
          styles.creatorHidden
        )}
      />
      <img
        src={creator2}
        alt="creator"
        className={classNames(
          styles.creator,
          styles.creator2,
          styles.creatorHidden
        )}
      />

      <div className={styles.motivation__content}>
        <header
          className={classNames(
            styles.section__header,
            styles.motivation__header
          )}
        >
          {t("motivation__header")}
        </header>

        <h2
          className={classNames(
            styles.section__subtitle,
            styles.motivation__title
          )}
        >
          {t("motivation__title")}
        </h2>

        <div
          className={classNames(
            styles.section__description,
            styles.motivation__text
          )}
        >
          <p>{t("motivation__text-1")}</p>
          <p>{t("motivation__text-2")}</p>
        </div>

        <ButtonLink
          className={classNames(styles.section__link, styles.motivation__link)}
          url={book.login}
        >
          {t("button__more")}
        </ButtonLink>
      </div>
    </section>
  );
};
