import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

import { Benefit } from "../../types";
import classNames from "classnames";

type Props = {
  benefit: Benefit;
  className?: string;
  isActive?: boolean;
};

export const ItemComponent: FC<Props> = ({ benefit, isActive }) => {
  const { t } = useTranslation();

  return (
    <li
      className={classNames(styles.itemWrapper, {
        [styles.itemWrapperActive]: isActive,
      })}
    >
      <div className={styles.item} data-id={benefit.id}>
        <div className={styles.item__content}>
          <img
            src={benefit.src}
            alt={benefit.name}
            className={styles.item__image}
          />

          <h3 className={styles.item__name}>{t(benefit.title)}</h3>
          <div className={styles.item__description}>
            {t(benefit.description)}
          </div>
        </div>
      </div>
    </li>
  );
};
