import React, { useEffect, useRef, useState } from "react";

import styles from "./styles.module.scss";

import { BENEFITS } from "../constants";
import { ItemComponent } from "./item";

export const CardsComponent = () => {
  const [activeCard, setActiveCard] = useState<number | null>(0);
  const list = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    list.current?.addEventListener("mouseenter", () => setActiveCard(null), {
      once: true,
    });
  }, []);

  return (
    <ul className={styles.list} ref={list}>
      {BENEFITS.map((benefit, index) => (
        <ItemComponent
          benefit={benefit}
          className={styles.card}
          key={benefit.id}
          isActive={activeCard === index}
        />
      ))}
    </ul>
  );
};
