import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import styles from "./styles.module.scss";

import logo from "../../assets/images/logo.svg";

import { book } from "../../navigation/book";
import { ButtonLink } from "../../shared/button/button-link";

export const AboutComponent: FC = () => {
  const { t } = useTranslation();

  return (
    <section className={classNames(styles.section, styles.section__about)}>
      <div className={styles.about__image}>
        <img src={logo} alt="programm logo" />
      </div>

      <div className={styles.about__descriptionWrapper}>
        <h1 className={styles.about__header}>{t("about__header")}</h1>

        <div className={styles.about__description}>
          <p className={classNames(styles.section__description)}>
            <b>{t("about__title")}</b>
          </p>

          <p className={styles.section__description}>{t("about__text-1")}</p>
        </div>
      </div>

      <ButtonLink className={styles.section__link} url={book.login}>
        {t("button__apply")}
      </ButtonLink>
    </section>
  );
};
