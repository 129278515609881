import DooM49 from "../../assets/images/creators/Creator 02 - @DooM49.png";
import AbsoluteAJ from "../../assets/images/creators/Creator 03 - @AbsoluteAJ.png";
import Rasco100 from "../../assets/images/creators/Creator 04 - @Rasco100.png";
import MCFixer from "../../assets/images/creators/Creator 05 - @MCFixer.png";
import DonMiwell from "../../assets/images/creators/Creator 06 - @DonMiwell.png";
import CasCandy from "../../assets/images/creators/Creator 07 - @CasCandy.png";
import Scythe from "../../assets/images/creators/Creator 08 - @Scythe.png";
import CyborgAngel from "../../assets/images/creators/Creator 09 - @CyborgAngel.png";
import JerichoFive from "../../assets/images/creators/Creator 10 - @JerichoFive.png";
import C0rnyyy from "../../assets/images/creators/Creator 11 - @C0rnyyy.png";
import Jost from "../../assets/images/creators/Creator 12 - @Jost.png";

import { Creator } from "./types";

export const CREATORS: Creator[] = [
  {
    name: "@DooM49",
    src: DooM49,
    link: "https://www.youtube.com/@doom49",
    quote:
      "The Ubisoft Creators Program empowers creators to unleash their imagination and connect with audiences worldwide",
  },
  {
    name: "@AbsoluteAJ",
    src: AbsoluteAJ,
    link: "https://www.twitch.tv/AbsoluteAJ",
    quote:
      "The Ubisoft Creator Program has provided me with amazing opportunities that I can share with my community which I wouldn’t usually have had",
  },
  {
    name: "@Rasco100",
    src: Rasco100,
    link: "https://www.twitch.tv/rasco100",
    quote:
      "The Ubisoft Creators Program allows creators to collaborate from across the world to make great content",
  },
  {
    name: "@MCFixer",
    src: MCFixer,
    link: "https://www.twitch.tv/mcfixer",
    quote:
      "This program provides creators of all sizes with access to Ubisoft games and empowers creators to create content for Ubisoft titles",
  },
  {
    name: "@DonMiwell",
    src: DonMiwell,
    link: "https://www.twitch.tv/donmiwell",
    quote: "Todo el nuevo contenido gracias a Ubisoft!",
  },
  {
    name: "@CasCandy",
    src: CasCandy,
    link: "https://beacons.ai/cascandy",
    quote:
      "The Ubisoft Creators Program has helped me to network with other creators that play the same games as me",
  },
  {
    name: "@Scythe",
    src: Scythe,
    link: "https://www.twitch.tv/scythe",
    quote:
      "Cumplí un sueño, asistiendo como invitado al Six Invitational, además de ayudar constantemente a mi canal con contenido exclusivo",
  },
  {
    name: "@CyborgAngel",
    src: CyborgAngel,
    link: "https://twitch.tv/cyborgangel",
    quote:
      "The creator program helps so much with ease of access. Notifications of new opportunities all in one place in one place is the best!",
  },
  {
    name: "@JerichoFive",
    src: JerichoFive,
    link: "https://www.twitch.tv/jerichofive",
    quote:
      "With the Ubisoft Creators Program I can always look and chose the campaigns that fit my channels best!",
  },
  {
    name: "@C0rnyyy",
    src: C0rnyyy,
    link: "https://www.youtube.com/c0rnyyy",
    quote:
      "The exclusive assets and campaigns from the Ubisoft Creators Program help me a lot when producing videos, as they provide me with new ideas and the appropriate visuals.",
  },
  {
    name: "@Jost",
    src: Jost,
    link: "https://www.twitch.tv/jost",
    quote:
      "With the Ubisoft Creators Program I have access to exclusive campaigns and I'm supported in what I love to do most, playing Rainbow Six Siege!",
  },
];
