import React, { FC } from "react";

import { SliderComponent } from "../../../shared/slider";
import { CREATORS } from "../constants";
import { CreatorComponent } from "../creator";

const BASIC_SLIDE_WIDTH = 250;

export const CreatorsSliderComponent: FC = () => (
  <SliderComponent basisSlideWidth={BASIC_SLIDE_WIDTH} arrows>
    {CREATORS.map((creator) => (
      <div key={creator.name}>
        <CreatorComponent creator={creator} />
      </div>
    ))}
  </SliderComponent>
);
