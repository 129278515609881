export const FAQ = [
  {
    summary: "faq__section-2__summary",
    details: "faq__section-2__details",
  },
  {
    summary: "faq__section-1__summary",
    details: "faq__section-1__details",
  },
  {
    summary: "faq__section-4__summary",
    details: "faq__section-4__details",
  },
  {
    summary: "faq__section-5__summary",
    details: "faq__section-5__details",
  },
  {
    summary: "faq__section-3__summary",
    details: "faq__section-3__details",
  },
];
