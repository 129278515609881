import React, { AnchorHTMLAttributes, FC, ReactNode } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

type ButtonLinkProps = {
  url: string;
  kind?: "primary" | "secondary";
  className?: string;
  children: ReactNode;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export const ButtonLink: FC<ButtonLinkProps> = ({
  children,
  url,
  className,
  kind = "primary",
  ...props
}) => {
  return (
    <a
      href={url}
      className={classNames(styles.buttonLink, className)}
      data-kind={kind}
      {...props}
    >
      {children}
    </a>
  );
};
