import age from "../../assets/images/criteria/age.svg";
import camera from "../../assets/images/criteria/camera.svg";
import play from "../../assets/images/criteria/play.svg";
import file from "../../assets/images/criteria/file.svg";

export const CRITERIA_LIST_MUST = [
  {
    name: "age",
    text: "criteria__age",
    src: age,
  },
  {
    name: "file",
    text: "criteria__file",
    src: file,
  },
];

export const CRITERIA_LIST_SHOULD = [
  {
    name: "play",
    text: "criteria__play",
    src: play,
  },
  {
    name: "views",
    text: "criteria__views",
    src: camera,
  },
];
