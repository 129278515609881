import { ENV } from "./types";

export const SCROLL_CONFIG = {
  duration: 500,
  offset: -50,
};

export const BUILD_MODES = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
};

export const UBI_SERVICES_PUBLIC_ENDPOINT = {
  [ENV.DEV]: "https://uat-public-ubiservices.ubi.com",
  [ENV.UAT]: "https://uat-public-ubiservices.ubi.com",
  [ENV.CERT]: "https://cert2-ubiservices.ubi.com",
  [ENV.PROD]: "https://public-ubiservices.ubi.com",
};

/* The constant CI_ENVIRONMENT_NAME will be set by Gitlab on deploy stage, and should be one of existing ENVs */
const CI_ENVIRONMENT_NAME = "PROD" as ENV;

export const ENVIRONMENT = Object.values(ENV).includes(CI_ENVIRONMENT_NAME)
  ? CI_ENVIRONMENT_NAME
  : ENV.DEV;

const globalNavigationUAT =
  "https://global-navigation-non-prod.s3.amazonaws.com/uat/1/global-navigation.js";
const globalNavigationPROD =
  "https://static-dm.akamaized.net/global-navigation-prod/prod/1/global-navigation.js";
const globalNavigationCERT =
  "https://global-navigation-non-prod.s3.amazonaws.com/cert/1/global-navigation.js";

export const GLOBAL_NAVIGATION_URL = {
  [ENV.DEV]: globalNavigationUAT,
  [ENV.UAT]: globalNavigationUAT,
  [ENV.CERT]: globalNavigationCERT,
  [ENV.PROD]: globalNavigationPROD,
} as const;

export const APP_ID = "af2d3b10-036c-4b29-82a4-3ca25f8e4c6a";
export const GENOME_ID = "1cd53287-7804-4bdf-b94d-6ff387e71ed0";
