import axios from "axios";

export const source = axios.CancelToken.source();
export const http = axios.create({
  baseURL: "/",
  timeout: 15000,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
  cancelToken: source.token,
});
