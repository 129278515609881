import React, { FC, useLayoutEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import cover from "../../assets/images/backgrounds/web/section-1.png";

const TITLE = "Ubisoft Creators Program";
const DESCRIPTION =
  "The Ubisoft Creators Program is a new program designed for video makers and live streamers that aims at empowering their voices and personalities and helping them grow their channels and communities.";

export const HelmetComponent: FC = () => {
  const { i18n } = useTranslation();
  const [origin, setOrigin] = useState("");

  useLayoutEffect(() => {
    setOrigin(window?.location.origin);
  }, []);

  return (
    <Helmet>
      <title>{TITLE}</title>

      <meta name="twitter:card" content="summary" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={origin} />
      <meta property="og:image" content={cover} />
      <meta property="og:image:alt" content="preview" />
      <meta property="og:title" content={TITLE} />
      <meta name="og:description" content={DESCRIPTION} />
      <meta name="og:locale" content={i18n.language} />
    </Helmet>
  );
};
