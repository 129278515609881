import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";

import styles from "./styles.module.scss";

import { book } from "../../navigation/book";
import { ButtonLink } from "../../shared/button/button-link";
import { CarouselComponent } from "./slider";
import { CardsComponent } from "./list";
import { Anchors } from "../../shared/types";
import { Media } from "../../shared/media/types";

export const BenefitsComponent: FC = () => {
  const { t } = useTranslation();
  const isLaptopM = useMediaQuery({ minWidth: Media.LM });

  return (
    <section
      className={classNames(styles.section, styles.section__benefits)}
      id={Anchors.BENEFITS}
    >
      <header
        className={classNames(styles.section__header, styles.benefits__header)}
      >
        {t("benefits__header")}
      </header>

      <div className={styles.benefits__wrapper}>
        {isLaptopM ? <CardsComponent /> : <CarouselComponent />}
      </div>

      <ButtonLink className={styles.section__link} url={book.login}>
        {t("button__start")}
      </ButtonLink>
    </section>
  );
};
