import React, { FC, useState, useLayoutEffect, useRef, useEffect } from "react";
import Slider, { Settings } from "react-slick";
import { useResizeDetector } from "react-resize-detector";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styles from "./styles.module.scss";

import { DEFAULT_SLIDES_AMOUNT } from "./constants";
import { Media } from "../media/types";

type Props = {
  basisSlideWidth: number;
  activeSlide?: number;
  className?: string;
} & Settings;

export const SliderComponent: FC<Props> = ({
  children,
  basisSlideWidth,
  activeSlide,
  className,
  ...settings
}) => {
  const { width, ref } = useResizeDetector();
  const sliderRef = useRef<Slider>(null);
  const isLaptopS = useMediaQuery({ minWidth: Media.LS });

  const domElements = useRef<{
    slickList: null | HTMLElement;
    slides: null | HTMLElement[];
  }>({
    slickList: null,
    slides: null,
  });

  const [slidesToShow, setSlidesToShow] = useState(
    settings.slidesToShow ?? DEFAULT_SLIDES_AMOUNT
  );

  const isArrowsAvailable = (settings.arrows ?? false) && isLaptopS;

  useLayoutEffect(() => {
    if (!width || !basisSlideWidth) {
      return;
    }

    const slidesToShow = Math.max(
      Math.floor(width / basisSlideWidth),
      DEFAULT_SLIDES_AMOUNT
    );

    setSlidesToShow(slidesToShow);
  }, [basisSlideWidth, width, settings.slidesToShow]);

  useLayoutEffect(() => {
    if (activeSlide !== undefined) {
      sliderRef.current?.slickGoTo(activeSlide);
    }
  }, [sliderRef, activeSlide]);

  useEffect(() => {
    const slickList = ref.current.querySelector(".slick-list");
    const slides = slickList?.querySelectorAll(".slick-slide");

    domElements.current = {
      slickList,
      slides,
    };
  }, [ref]);

  // Fix problem with accessibility and interaction with elements which are out of view
  // The FF issue is still open
  const changeSlidesAccessibility = () => {
    const { slides } = domElements.current;

    slides?.forEach((slide) => {
      slide.classList.contains("slick-active")
        ? slide.removeAttribute("inert")
        : slide.setAttribute("inert", String(true));
    });
  };

  useEffect(() => changeSlidesAccessibility(), [slidesToShow]);

  return (
    <div ref={ref} className={classNames(styles.slider, className)}>
      <Slider
        dots={settings.dots ?? true}
        arrows={isArrowsAvailable}
        infinite={settings.infinite ?? false}
        swipeToSlide={settings.swipeToSlide ?? true}
        variableWidth={settings.variableWidth ?? false}
        slidesToScroll={settings.slidesToScroll ?? 1}
        slidesToShow={slidesToShow}
        afterChange={changeSlidesAccessibility}
        speed={settings.speed ?? 500}
        rows={settings.rows ?? 1}
      >
        {children}
      </Slider>
    </div>
  );
};
