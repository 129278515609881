import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import classNames from "classnames";

import styles from "./styles.module.scss";

import { book } from "../../navigation/book";
import { ButtonLink } from "../../shared/button/button-link";
import { CRITERIA_LIST_MUST, CRITERIA_LIST_SHOULD } from "./constants";
import { CriteriaList } from "./critetia-item";

export const RequirementsComponent: FC = () => {
  const { t } = useTranslation();

  return (
    <section className={classNames(styles.section, styles.section__criteria)}>
      <header
        className={classNames(styles.section__header, styles.criteria__header)}
      >
        {t("criteria__header")}
      </header>

      <div className={styles.criteria__content}>
        <div
          className={classNames(
            styles.section__description,
            styles.criteria__description
          )}
        >
          <p>
            <Trans
              i18nKey="criteria__text-1"
              components={{
                strong: <strong />,
              }}
            />
          </p>
          <p>{t("criteria__text-2")}</p>
        </div>

        <div className={styles.criteria__container}>
          <h3 className={styles.criteria__subtitle}>
            {t("criteria__must__title")}
          </h3>
          <h3
            className={classNames(
              styles.criteria__subtitle,
              styles.criteria__should
            )}
          >
            {t("criteria__should__title")}
          </h3>

          <CriteriaList criteria={CRITERIA_LIST_MUST} />
          <CriteriaList criteria={CRITERIA_LIST_SHOULD} />

          <div className={styles.criteria__footnote}>
            {t("criteria__should__footnote")}
          </div>
        </div>
      </div>

      <ButtonLink className={styles.section__link} url={book.login}>
        {t("button__more")}
      </ButtonLink>
    </section>
  );
};
