import { replaceUrlParams } from "../api/services/utils";
import i18n from "../i18n";
import { ENV } from "../shared/types";
import { ENVIRONMENT } from "../shared/constants";

const UBI_ACCOUNT_LINK = {
  [ENV.DEV]: "https://uat.account.ubisoft.com/account-information",
  [ENV.UAT]: "https://uat.account.ubisoft.com/account-information",
  [ENV.CERT]: "https://cert2.account.ubisoft.com/account-information",
  [ENV.PROD]: "https://account.ubisoft.com/account-information",
};

export const ExternalLinks = {
  get codeOfConduct() {
    return replaceUrlParams(
      "https://www.ubisoft.com/{locale}/help/player-safety/article/code-of-conduct-the-way-we-play/000095037",
      {
        locale: i18n.language,
      }
    );
  },

  get ubiAccount() {
    return UBI_ACCOUNT_LINK[ENVIRONMENT];
  },
};
