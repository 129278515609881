import i18n from "i18next";

import { FALLBACK_LNG } from "../i18n/languages";

export const book = {
  get locale() {
    return `/${(i18n.language || FALLBACK_LNG).toLowerCase()}`;
  },

  get login() {
    return `${this.locale}/login`;
  },
};
