import React, { FC } from "react";

import styles from "./styles.module.scss";

import { BENEFITS } from "../constants";
import { SliderComponent } from "../../../shared/slider";
import { SlideComponent } from "./slide";

const BASIC_SLIDE_WIDTH = 250;

export const CarouselComponent: FC = () => (
  <SliderComponent basisSlideWidth={BASIC_SLIDE_WIDTH}>
    {BENEFITS.map((benefit) => (
      <SlideComponent
        key={benefit.id}
        benefit={benefit}
        className={styles.slide}
      />
    ))}
  </SliderComponent>
);
