import React from "react";

import styles from "./styles.module.scss";

import loader from "../../assets/images/loader.gif";

export const LoadingComponent = () => (
  <div className={styles.loading}>
    <img src={loader} alt="loading..." />
  </div>
);
