import React, { FC, ReactNode, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { navigate } from "gatsby";

import {
  COUNTRY_LANGUAGE,
  FALLBACK_LNG,
  SUPPORTED_LANGUAGES,
} from "./languages";
import "../i18n";
import { ubiApiService } from "../api/services/ubi-service";
import { LoadingComponent } from "../shared/spinner";

type Props = {
  children: ReactNode;
} & RouteComponentProps;

export const LocaleRouter: FC<Props> = ({ children }) => {
  const { i18n, ready } = useTranslation();
  const [isLocaleDefine, setIsLocaleDefine] = useState(false);

  const isLocaleInitialized = ready && isLocaleDefine;

  useEffect(() => {
    (async () => {
      const locale = window.location.pathname.replace(/^\//, "");
      const supportedLng = [...SUPPORTED_LANGUAGES].find(
        (lng) => lng.toLowerCase() === locale.toLowerCase()
      );

      if (!supportedLng) {
        const { data } = await ubiApiService.fetchIpLocation();
        const { countryCode } = data;
        const countryLng = COUNTRY_LANGUAGE[countryCode];

        const updatedLang =
          SUPPORTED_LANGUAGES.find((lng) => lng === countryLng) ?? FALLBACK_LNG;

        await navigate(`/${updatedLang.toLowerCase()}`, { replace: true });
      } else {
        if (supportedLng.toLowerCase() !== locale) {
          // cover wrong locale format
          await navigate(`/${supportedLng.toLowerCase()}`, { replace: true });
        } else {
          await i18n.changeLanguage(supportedLng);

          setIsLocaleDefine(true);
        }
      }
    })();
  }, [i18n]);

  return isLocaleInitialized ? <>{children}</> : <LoadingComponent />;
};
