import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

import { Benefit } from "../../types";

type Props = {
  benefit: Benefit;
  className?: string;
};

export const SlideComponent: FC<Props> = ({ benefit }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.slide} data-id={benefit.id}>
      <div className={styles.slide__content}>
        <img
          src={benefit.src}
          alt={benefit.name}
          className={styles.slide__image}
        />

        <h3 className={styles.slide__name}>{t(benefit.title)}</h3>
        <div className={styles.slide__description}>
          {t(benefit.description)}
        </div>
      </div>
    </div>
  );
};
