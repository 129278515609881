import { AxiosResponse } from "axios";

import { http } from "../../config";
import { URLS } from "./urls";
import { setUbiPublicServicesApi } from "./utils";
import { IpLocation } from "./types";
import { APP_ID } from "../../../shared/constants";

export class UbiAPIService {
  public fetchIpLocation(): Promise<AxiosResponse<IpLocation>> {
    return http.request({
      method: "GET",
      url: setUbiPublicServicesApi(URLS.ipLocation),
      headers: {
        "Ubi-AppId": APP_ID,
      },
    });
  }
}

export const ubiApiService = new UbiAPIService();
