export enum Anchors {
  BENEFITS = "benefits",
}

export enum ENV {
  DEV = "DEV",
  UAT = "UAT",
  CERT = "CERT",
  PROD = "PROD",
}
