import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@material-ui/core";

import styles from "./styles.module.scss";

import { Icon } from "../icon";

type Props = {
  summary: string | React.ReactElement;
  details: string | React.ReactElement;
};

export const AccordionComponent: FC<Props> = ({ summary, details }) => {
  const { t } = useTranslation();

  return (
    <Accordion className={styles.accordion}>
      <AccordionSummary
        expandIcon={<Icon type="arrow" className={styles.accordion__arrow} />}
      >
        {typeof summary === "string" ? t(summary) : summary}
      </AccordionSummary>

      <AccordionDetails className={styles.accordion__details}>
        {typeof details === "string" ? t(details) : details}
      </AccordionDetails>
    </Accordion>
  );
};
