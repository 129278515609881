import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

import { ButtonLink } from "../../shared/button/button-link";
import { book } from "../../navigation/book";

export const FloatNavigationComponent: FC = () => {
  const { t } = useTranslation();

  return (
    <nav className={styles.floatNavigation}>
      <ButtonLink className={styles.floatNavigation__link} url={book.login}>
        {t("button__apply-now")}
      </ButtonLink>

      <ButtonLink
        className={styles.floatNavigation__link}
        kind="secondary"
        url={book.login}
      >
        {t("button__login")}
      </ButtonLink>
    </nav>
  );
};
