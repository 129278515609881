import key from "../../assets/images/benefits/key.svg";
import gift from "../../assets/images/benefits/gift.svg";
import ticket from "../../assets/images/benefits/ticket.svg";
import star from "../../assets/images/benefits/star.svg";

import { Benefit } from "./types";

export const BENEFITS: Benefit[] = [
  {
    id: 1,
    name: "Games & DLC",
    src: key,
    title: "benefits__games-title",
    description: "benefits__games-description",
  },
  {
    id: 2,
    name: "Community Rewards",
    src: gift,
    title: "benefits__rewards-title",
    description: "benefits__rewards-description",
  },
  {
    id: 3,
    name: "News & Events",
    src: ticket,
    title: "benefits__events-title",
    description: "benefits__events-description",
  },
  {
    id: 4,
    name: "Ubisoft Partnership",
    src: star,
    title: "benefits__partnership-title",
    description: "benefits__partnership-description",
  },
];
