import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

import { DEFAULT_NS, LOAD_PATH } from "./constants";
import { FALLBACK_LNG, SUPPORTED_LANGUAGES } from "./languages";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: FALLBACK_LNG,
    supportedLngs: SUPPORTED_LANGUAGES,
    backend: {
      loadPath: LOAD_PATH,
      allowMultiLoading: false,
    },
    ns: [DEFAULT_NS],
    defaultNS: DEFAULT_NS,
    nsSeparator: false,
    interpolation: {
      escapeValue: false,
    },
    returnEmptyString: false,
    react: {
      useSuspense: false,
    },
  });

export default i18n;
