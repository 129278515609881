import React, { FC } from "react";
import { Trans } from "react-i18next";
import classNames from "classnames";

import styles from "./styles.module.scss";

import { ExternalLinks } from "../../../navigation/external-links";
import { CriteriaItem } from "../types";

type Props = {
  criteria: CriteriaItem[];
};

export const CriteriaList: FC<Props> = ({ criteria }) => {
  return (
    <ul className={styles.criteria__list}>
      {criteria.map((criteria) => (
        <li key={criteria.name} className={styles.criteria__item}>
          <img
            src={criteria.src}
            alt={criteria.name}
            className={styles.criteria__img}
          />

          <div className={styles.item__text}>
            <Trans
              i18nKey={criteria.text}
              components={{
                strong: <strong />,
                codeOfConductLink: (
                  <a
                    className={classNames(styles.item__link, styles.link)}
                    href={ExternalLinks.codeOfConduct}
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                ),
              }}
            />
          </div>
        </li>
      ))}
    </ul>
  );
};
