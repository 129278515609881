import React from "react";
import { Router } from "@reach/router";

import "../styles/reset.css";
import "../styles/vars.css";
import "../styles/fonts.css";

import "wicg-inert";

import { MainPage } from "../domains/main/main.page";
import { LocaleRouter } from "../i18n/locale-router";
import { HelmetComponent } from "../shared/helmet";

export default function Index() {
  return (
    <>
      <HelmetComponent />

      <Router>
        <LocaleRouter path="/">
          <MainPage path=":locale" />
        </LocaleRouter>
      </Router>
    </>
  );
}
