import React, { FC } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

import { Creator } from "../types";
import { ButtonLink } from "../../../shared/button/button-link";
import { Icon } from "../../../shared/icon";

type Props = {
  creator: Creator;
  isActive?: boolean;
};

export const CreatorComponent: FC<Props> = ({ creator, isActive }) => (
  <figure
    className={classNames(styles.creator, {
      [styles.creatorActive]: isActive,
    })}
  >
    <img src={creator.src} alt={`creator ${creator.name}`} />

    <figcaption>
      <q className={styles.creator__quote}>{creator.quote}</q>

      <ButtonLink
        target="_blank"
        kind="secondary"
        url={creator.link}
        className={styles.creator__link}
        rel="noreferrer"
      >
        <span>{creator.name}</span>
        <Icon type="link" />
      </ButtonLink>
    </figcaption>
  </figure>
);
