import React, { FC } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

import arrow from "./icons/arrow.inline.svg";
import link from "./icons/link.inline.svg";

export const icons = {
  arrow,
  link,
};

export type IconsType = keyof typeof icons;

type Props = {
  type: IconsType;
  className?: string;
};

export const Icon: FC<Props> = ({ type, className, ...props }) => {
  const SVGIcon = icons[type];

  return (
    <i className={classNames(className, styles.icon)} {...props}>
      <SVGIcon />
    </i>
  );
};
