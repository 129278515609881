import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { OVERLAY_LANGUAGES } from "../../i18n/languages";

export const GlobalFooterComponent = () => {
  const { i18n } = useTranslation();
  const language = i18n.language?.toLowerCase();
  const [languageSelectorUrl, setLanguageSelectorUrl] = useState("");

  useLayoutEffect(() => {
    setLanguageSelectorUrl(`${window.location.origin}/{locale}`);
  }, []);

  return (
    <global-footer
      locale={language}
      class="global-footer--default"
      open-in-same-window="true"
      language-selector-url={languageSelectorUrl}
      language-selector-locales={OVERLAY_LANGUAGES}
      language-selector-layout="region"
    />
  );
};
